<template>
  <div class="content_container request_detail">
    <div class="top_section">
      <div class="inner">
        <div class="top_btn_wrap">
            <div class="top_btn" @click="this.$router.push('/request/sourcing')">
                <div class="icon_wrap">
                    <img
                    src="@/assets/images/main/icon_tab_03.svg"
                    alt="상품소싱요청"
                    />
                </div>
                <div class="text">
                    <h1>상품소싱요청</h1>
                    <p>
                        찾으시는 상품이 없으시면<br /> 메타바이셀 관리자에게 상품을
                        요청하세요.
                    </p>
                </div>
            </div>
            <div class="top_btn" @click="this.$router.push('/request/consulting')">
                <div class="icon_wrap">
                    <img src="@/assets/images/main/icon_tab_04.svg" alt="컨설팅" />
                </div>
                <div class="text">
                    <h1>거래중개 컨설팅</h1>
                    <p>
                        거래 진행에 어려움을 겪고 계시다면<br /> 전문 컨설턴트에게
                        문의하세요.
                    </p>
                </div>
            </div>
            <div class="top_btn" @click="this.$router.push('/request/inquire')">
                <div class="icon_wrap">
                    <img src="@/assets/images/common/icon_request.svg" alt="일반문의" />
                </div>
                <div class="text">
                    <h1>일반 문의</h1>
                    <p>
                        서비스를 이용하시면서 궁금하신 점이나<br /> 불편사항이 있으시다면
                        빠르게 처리해드립니다.
                    </p>
                </div>
            </div>
        </div>
      </div>
    </div>

    <div class="bottom_section">
      <template v-for="(item, i) in this.inquireData.inquire" :key="i">
        <div class="ask_title">
          <h1>
            <span>[{{ item.division }}]</span>{{ item.title }}
          </h1>
          <p>{{ this.dateFormat(item.enrollment_date) }}</p>
        </div>
        <div class="ask_content">
          <div class="inner">
            <p>
              {{ item.content }}
            </p>
          </div>
        </div>
      </template>
      <div class="answer">
        <p class="no_answer" v-if="this.inquireData.answer.length == 0">
          등록하신 문의는 처리 대기 중입니다. 잠시만 기다려 주세요.
        </p>
        <div class="inner" v-else>
          <template v-for="(item, i) in this.inquireData.answer" :key="i">
            <div class="image_wrap">
              <img
                src="@/assets/images/common/charactor_00.png"
                alt="임시 관리자"
              />
            </div>
            <div class="name">
              <h1>
                관리자 이름<span>{{
                  this.dateFormat(item.enrollment_date)
                }}</span>
              </h1>
            </div>
            <p>
              {{ item.content }}
            </p>
          </template>
        </div>
      </div>
      <button class="common_button" @click="this.$router.push('/request')">
        목록
      </button>
    </div>
  </div>
</template>

<script>
import { fetchData } from "@/utils/fetchData";
import { dateFormat } from "@/utils/dateFormat";

export default {
  props: {
    pk: String,
  },
  mixins: [fetchData, dateFormat],
  data() {
    return {
      inquireData: {
        inquire: [],
        answer: [],
      },
    };
  },
  mounted() {
    this.fetchInquireData();
  },
  methods: {
    fetchInquireData() {
      this.fetchData(
        `/getInquireDetail/${this.pk}`,
        (data) => {
          this.inquireData = data;
        },
      );
    },
  },
};
</script>

<style scoped src="@/assets/css/layout_request.css">
</style>